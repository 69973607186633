import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiRequest } from '../../../../../../utils/api';

const DeleteData = ({user, refresh}:any) => {

    const [editing,setEditing]=useState<boolean>(false)
    return (
      <div>
       
        
         <DeleteDataView user={user}/>
        

        <hr/>
      </div>
    )
  }
const DeleteDataView = ({userData}:any) => { 

    return (<>
        



    <div className="card card-frame">
      <div className="card-body ">
        This is some text within a card body.

        <hr/>
        
        <Button variant='danger'>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
        </svg>
          Eliminar conta
        </Button>
        
      </div>
    </div>
    </>)
}



interface PropertyFormInputs {
    email: Date;
    linkedin: string;
    instagram: string;
    telephone:string;
}
export const DeleteDataForm = ({userData, setEditing, refresh}:any) => {

    const [error, setErrors] = useState<any>({});
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<PropertyFormInputs>({
        defaultValues:{...userData}
    });

    const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) =>{
const {       linkedin, instagram,
        telephone,
        email}=data;
    
        formSubmit({/*linkedin, instagram,*/
        telephone,
        email} as any);

    }
        
    useEffect(() => {
        if (savedResponse !== undefined) {
            reset();
            refresh()
            setEditing(false)
        }
    }, [savedResponse])

    const formSubmit = (body: PropertyFormInputs) => {
        apiRequest({
            endpoint: '/users-data/'+userData?.id,
            method: 'put',
            body
        }).then((data) => {
            setSavedResponse(data)
        }).catch(({data})=>setErrors(data))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', padding: 15 }}>
            
            {error?.error &&
                <div className="alert alert-danger text-dark text-sm" role="alert">
                    <strong>Erro: </strong> {error?.error?.message}
                </div>
            }
            <Row>
            <Col md={12}>
                <label htmlFor="title">E-mail</label>
                <input
                    className={"form-control " + (errors.email && "is-invalid")}
                    maxLength={256}
                    data-name="Name"
                    placeholder="Nome completo"
                    type="email"

                    {...register("email", { required: "Email is required" })}
                >
                </input>
                <span className='error'>{errors.email && <p>{errors.email.message}</p>}</span>
            </Col>


<Col md={12}>
    <label htmlFor="title">Telefone</label>
    <input
        className={"form-control " + (errors.telephone && "is-invalid")}
        maxLength={256}
        data-name="Name"
        placeholder="Nome completo"
        type="number"

        {...register("telephone", { required: "Telefone is required" })}
    >
    </input>
    <span className='error'>{errors.telephone && <p>{errors.telephone.message}</p>}</span>
</Col>


<Col md={12}>
    <label htmlFor="title">LinkedIn</label>
                    <input
                        
        className={"form-control " + (errors.linkedin && "is-invalid")}
        maxLength={256}
        data-name="Name"
        placeholder="LinkedIn"
        type="text"

                        {...register("linkedin")}
                        >
    </input>
    <span className='error'>{errors.linkedin && <p>{errors.linkedin.message}</p>}</span>
</Col>


<Col md={12}>
    <label htmlFor="title">Instagram</label>
                    <input
                        
        className={"form-control " + (errors.instagram && "is-invalid")}
        maxLength={256}
        data-name="Name"
        placeholder="Instagram"
        type="text"

                        {...register("instagram")}
                        >
    </input>
    <span className='error'>{errors.instagram && <p>{errors.instagram.message}</p>}</span>
</Col>


            </Row>
            <hr />
            <button type="submit" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2" >
                <span className="btn-inner--icon">

                </span>
                <span className="btn-inner--text">Registar novo</span>

            </button>

        </form>
    );
}
export default DeleteData
