import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiRequest } from '../../../../../../../utils/api';

const EventDelete = ({ event,refresh, children }: any) => {

    const [show, setShow] = useState<boolean>(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <span onClick={handleShow}>{children}</span>
            <DeleteModel show={show} setShow={setShow} event={event} refresh={ refresh} />    
</>
    )
}

const DeleteModel = ({ show, setShow, event , refresh}: any) => {
    

    const navigate = useNavigate()

     const handleDeletEvent =()=>{
        apiRequest({
            endpoint: '/events/' + event?.id,
            method: 'delete',
        }).then(({ data }: any) => {
            if (data) {
refresh()

setShow(false)
            Swal.fire({
                title: "Evento!",
                text: "Evento "+event?.title+ " Eliminado com sucesso!",
                icon: "success"
                });
            }
        }).catch(err => console.error(err))
     }
    
    return (<>
     <Modal show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{event?.id ? "Actualizar":"Registar novo"}  Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Atenção:</p>

                    <p>Você está prestes a Eliminar o eventa <b>{ event?.title}</b> do sistema.

Tem certeza de que deseja continuar? A remoção do eventa não tem volta.</p>
<p>
                    Aviso: Certifique-se de que não há dados importantes associados ao eventa antes de prosseguir.
                    </p>
                
                <button className='btn btn-danger'>
                    
                    
                    <span className="btn-inner--icon">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
		<path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
	</span>
  <span className="btn-inner--text" onClick={handleDeletEvent}>Confirmo, Eliminar Evento</span>
                    </button>
            </Modal.Body>
        </Modal>
    </>)
}


export default EventDelete
