import React from 'react'
import { ProgressBar } from 'react-bootstrap'

export const Loading = () => {
    return (
        <div>Loading</div>
    )
}


const PulseLoading = () => {
    return (<>
        <div className="spinner-grow" role="status">
            <span className="sr-only">...</span>
        </div>
    </>)
}

const ProgressLoading = () => <ProgressBar animated now={100} />
export { PulseLoading, ProgressLoading }