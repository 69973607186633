import React, { useEffect, useState } from 'react'
import { apiRequest } from '../../../../../../../utils/api';
import EventCreate from './EventCreate';
import moment from 'moment';
import { env } from 'process';
import EventDelete from './EventDelete';
import { ProgressLoading } from '../../../../../../Common/components/Loading';
import { loadavg } from 'os';
import { Button } from 'react-bootstrap';

const EventList = ({ program }: any) => {


  const [{ data: events, ...data }, setEvents] = useState<any>({})
  const [selectedEvent, setEvent] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [showProgramCreateModal, setShowProgramCreateModal] = useState(false);
  useEffect(() => {
    if (program?.id) {
      setLoading(true)
      apiRequest({
        endpoint: '/events',
        method: 'get',
        urlQueryParams: { page: currentPage, limit: 6, programId: program?.id }
      }).then((data) => {
        setEvents(data)
      }).catch(err => console.error(err))
        .finally(() =>
          setLoading(false))
    }
  }, [program, currentPage, refresh])

  const handleEditEvent = (event: any) => {
    setEvent(event);
    setShowProgramCreateModal(true)
  }
  return (
    <div className="table-responsive p-0">

      <EventCreate refresh={() => setRefresh(Math.random())} show={showProgramCreateModal} event={{ ...selectedEvent, program }} setShow={setShowProgramCreateModal} />
      <table className="table align-items-center justify-content-center mb-0">
        <thead className="bg-gray-100">
          <tr>
            <th className="text-secondary text-xs font-weight-semibold opacity-7">Evento</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">Data de Inicio</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">Data de Fim</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">Account</th>
            <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">

              <Button size="sm" variant="outline-dark" onClick={() => handleEditEvent(undefined)} >
                <svg fill="#000000" width="14" height="14" viewBox="-4.5 -4.5 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-plus"><path d='M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z' /></svg>
              </Button>

            </th>
          </tr>
        </thead>
        <tbody>
          {events?.map((event: any) =>
            <tr>
              <td>
                <div className="d-flex px-2">
                  <div className="avatar avatar-sm rounded-circle bg-gray-100 me-2 my-2">
                    <img src="/assets/img/small-logos/logo-spotify.svg" className="w-80" alt="spotify" />
                  </div>
                  <div className="my-auto">
                    <h6 className="mb-0 text-sm">{event?.title}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p className="text-sm font-weight-normal mb-0">{moment(event?.startEventDate).format('DD/MM/YYYY')}</p>
              </td>
              <td>
                <span className="text-sm font-weight-normal">{moment(event?.endEventDate).format('DD/MM/YYYY')}</span>
              </td>
              <td className="align-middle">

                <span className="text-sm font-weight-normal">{moment(event?.createdAt).format('DD/MM/YYYY')}</span>
              </td>
              <td className="align-middle">
               
                <Button variant="outline-dark" size="sm"  onClick={() => handleEditEvent(event)}>
                  <svg width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z" fill="#64748B"></path>
                  </svg>
                </Button>
                <EventDelete event={event} refresh={() => setRefresh(Math.random())} >

                  <Button variant="outline-dark" size="sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </Button>
                </EventDelete>
              </td>
            </tr>)}

        </tbody>
      </table>
      {loading && <ProgressLoading />}
    </div>
  )
}

export default EventList
