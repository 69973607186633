import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../../../features/auth/authSlice';
import { RegisterData } from '../../../features/auth/types';
import { AppDispatch, RootState } from '../../../app/store';

import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ProgressLoading, PulseLoading } from '../../Common/components/Loading';
import { useNavigate } from 'react-router-dom';

interface LogonFormInput {
  email: string,
  password: string,
  name: string,
  termsConditions: string
}

const LogonForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state?.auth);

  const [loading, setLoading] = useState<boolean>(false)
  const { register, handleSubmit, reset, formState: { errors } } = useForm<LogonFormInput>();

  const onSubmit: SubmitHandler<LogonFormInput> = (data: any) =>
    formSubmit(data);

  const formSubmit = (data: LogonFormInput) => {
    setLoading(true)
    dispatch(registerUser(data));
  };

  useEffect(() => {
    setLoading(false)
    if (auth.user?.email) {
      navigate('/my/dashboard');
    }
  }, [auth])
  return (
    <>
      {auth?.error && <div className="shadow-none p-3 mb-5 bg-body-tertiary rounded">E-mail e/ou palavra-passe errada, certifique que escreveu correctamente</div>}

      {auth?.error?.message &&
        <div className="alert alert-danger" role="alert">
          {auth?.error?.message}
        </div>
      }

      <form role="form" onSubmit={handleSubmit(onSubmit)} >

        <div>
          <label htmlFor="title">Nome</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="name"
            placeholder="Nome completo"
            type="text"

            {...register("name", { required: "Este campo é obrigatorio" })}
          >
          </input>
          <span className='error'>{errors.name && <p>{errors.name.message}</p>}</span>
        </div>


        <div>
          <label htmlFor="title">Email</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="email"
            placeholder="E-mail"
            type="email"

            {...register("email", { required: "Este campo é obrigatorio" })}
          >
          </input>
          <span className='error'>{errors.email && <p>{errors.email.message}</p>}</span>
        </div>

        <div>
          <label htmlFor="title">Palavra-passe</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="email"
            placeholder="Palavra-passe"
            type="password"

            {...register("password", { required: "Este campo é obrigatorio" })}
          >
          </input>
          <span className='error'>{errors.password && <p>{errors.password.message}</p>}</span>
        </div>

        <div className="form-check form-check-info text-left mb-0">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"

            {...register("termsConditions", { required: "Para avançar é obrigatório concordares com os Termos e Condições" })} />
          <label className="font-weight-normal text-dark mb-0" htmlFor="flexCheckDefault">
            Eu concordo com o <a href="javascript:;" className="text-dark font-weight-bold">Termos e Condições</a>.
          </label>
          <span className='error'>{errors.termsConditions && <p>{errors.termsConditions.message}</p>}</span>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-dark w-100 mt-4 mb-3" disabled={loading}>{loading && <PulseLoading />}{" "}Registar-se in</button>
          <button type="button" className="btn btn-white btn-icon w-100 mb-3">
            <span className="btn-inner--icon me-1">
              <img className="w-5" src="../assets/img/logos/google-logo.svg" alt="google-logo" />
            </span>
            <span className="btn-inner--text">Cadastre-se com o Google</span>
          </button>
        </div>
        {loading && <ProgressLoading />}
      </form>
    </>
  );
};

export default LogonForm;