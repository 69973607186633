


import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { apiRequest } from '../../../../../utils/api';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { ProgressLoading, PulseLoading } from '../../../../Common/components/Loading';


interface PropertyFormInputs {

    title: string,
    description: string,
    startEventDate: string,
    endEventDate: string,
    isActive?: boolean,
}
export const ProgramCreateForm = ({ refresh, closeModal, program }: any) => {

    const [error, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<PropertyFormInputs>({
        defaultValues: {
            ...program,
            startEventDate: program?.startEventDate && moment(program?.startEventDate).format('YYYY-MM-DD'),
            endEventDate: program?.endEventDate && moment(program?.endEventDate).format('YYYY-MM-DD')
        }
    });

    const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) =>
        formSubmit(data);

    useEffect(() => {
        if (savedResponse !== undefined) {
            reset();
            refresh();
            closeModal()
        }
    }, [savedResponse])

    const formSubmit = (body: PropertyFormInputs) => {
        setLoading(true)
        apiRequest({
            endpoint: '/programs' + (program?.id ? "/" + program?.id : ""),
            method: program?.id ? 'put' : 'post',
            body
        }).then((data) => {
            setSavedResponse(data)
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', margin: '0 auto', padding: 15 }}>

            {error?.error && <div className='alert alert-danger'>{error?.error?.message}</div>}
            <Row>
                <Col xs={12}>
                    <label htmlFor="title">Titulo</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Titulo"
                        type="text"

                        {...register("title", { required: "Email is required" })}
                    >
                    </input>
                    <span className='error'>{errors.title && <p>{errors.title.message}</p>}</span>
                </Col>

                <Col xs={12}>
                    <label htmlFor="title">Descricao</label>
                    <textarea
                        className="form-control ps-0"
                        data-name="title"
                        placeholder="Descricao do Programa"
                        rows={8}

                        {...register("description", { required: "Email is required" })}
                    >
                    </textarea>
                    <span className='error'>{errors.description && <p>{errors.description.message}</p>}</span>
                </Col>

                <Col md={6}>
                    <label htmlFor="title">Data de Inicio</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Data de inicio"

                        type="date"

                        {...register("startEventDate", { required: "Date is required" })}
                    >
                    </input>
                    <span className='error'>{errors.startEventDate && <p>{errors.startEventDate.message}</p>}</span>
                </Col>

                <Col md={6}>
                    <label htmlFor="title">Data de Fim</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Titulo"
                        type="date"

                        {...register("endEventDate", { required: "Date is required" })}
                    >
                    </input>
                    <span className='error'>{errors.endEventDate && <p>{errors.endEventDate.message}</p>}</span>
                </Col>

                <Col>


                    <hr />
                    <button type="submit" className="btn btn-dark btn-icon d-flex align-items-center me-2" disabled={loading}>
                        {loading ? <PulseLoading /> : <span className="btn-inner--icon">

                        </span>}
                        <span className="btn-inner--text">Salvar</span>

                    </button>
                </Col>
            </Row>
            {loading && <ProgressLoading />}
        </form>
    );
}
