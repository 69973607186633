import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiRequest } from '../../../../../../../utils/api';
import { ProgressLoading, PulseLoading } from '../../../../../../Common/components/Loading';

const MentorDelete = ({ mentor, refresh, children }: any) => {

    const [show, setShow] = useState<boolean>(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <span onClick={handleShow}>{children}</span>
            <DeleteModel show={show} setShow={setShow} mentor={mentor} refresh={refresh} />
        </>
    )
}

const DeleteModel = ({ show, setShow, mentor, refresh }: any) => {
    const [loading, setLoading] = useState<any>(false)

    const navigate = useNavigate()

    const handleDeletMentor = () => {

        setLoading(true)
        apiRequest({
            endpoint: '/Program-mentors/' + mentor?.id,
            method: 'delete',
        }).then((data: any) => {
            if (data) {

                setShow(false)
                Swal.fire({
                    title: "Mentoro!",
                    text: "Mentor " + mentor?.user?.userData?.firstName + ' ' + mentor?.user?.userData?.lastName + " Eliminado com sucesso!",
                    icon: "success"
                });
                refresh()
            }
        }).catch(err => console.error(err))
            .finally(() =>
                setLoading(false))
    }

    return (<>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{mentor?.id ? "Actualizar" : "Registar novo"}  Mentoro</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Atenção:</p>

                <p>Você está prestes a Eliminar o mentora <b>{mentor?.title}</b> do sistema.

                    Tem certeza de que deseja continuar? A remoção do mentora não tem volta.</p>
                <p>
                    Aviso: Certifique-se de que não há dados importantes associados ao mentora antes de prosseguir.
                </p>

                <button className='btn btn-danger' disabled={loading}>

                {loading ? <PulseLoading />
                    :<span className="btn-inner--icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                    </span>}{" " }
                    <span className="btn-inner--text" onClick={handleDeletMentor}>Confirmo, Eliminar Mentoro</span>
                </button>
            </Modal.Body>

            {loading && <ProgressLoading />}
        </Modal>
    </>)
}


export default MentorDelete
