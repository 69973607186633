import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Col, Row, Spinner } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { apiRequest } from '../../../../../../utils/api'
import moment from 'moment'
import Swal from 'sweetalert2'
import { ProgressLoading } from '../../../../../Common/components/Loading'

import './UserRole.scss';

const UserRole = ({ user, refresh }: any) => {

    return (
        <div id={"UserRole"}>
            <Row>
                <Col md={8}>
                    <h3 className="display-6">Perfil de Acesso</h3>
                </Col>
            </Row>
            <UserRoleForm user={user} refresh={refresh} />



            <hr />
        </div>
    )
}




const UserRoles = ['USER', 'MENTOR', 'ADMIN']

export const UserRoleForm = ({ user, refresh }: any) => {

    const [error, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const [userRole, setUserRole] = useState<string>(user?.role);


    useEffect(() => {
        if (savedResponse !== undefined) {

            Swal.fire({
                title: 'Actualziação de perfil',
                text: 'Perfil do utilizador ' + user?.email + ' actualizado de "' + user?.role + '" para  "' + userRole + '"',
                icon: 'info',
                confirmButtonText: 'Okay'
            })
            refresh()

        }
    }, [savedResponse])

    useEffect(() => {
        if (userRole !== user?.role)
            formSubmit()
    }, [userRole])

    const formSubmit = () => {
        setLoading(true);
        apiRequest({
            endpoint: '/users/' + user?.id,
            method: 'put',
            body: { role: userRole }
        }).then((data) => {
            
            setSavedResponse(data)
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false));
    }

    return (
        <>


            <div className="card card-frame">
                <div className="card-body ">
                    Alterar o perfil de acesso do utilizador

                    <hr />




                    {error?.error &&
                        <div className="alert alert-danger text-dark text-sm" role="alert">
                            <strong>Erro: </strong> {error?.error?.message}
                        </div>
                    }
                    <Row>
                        <Col md={12}>

                            <ButtonGroup size="lg" className="mb-2" >
                                {UserRoles.map((role: string) => <Button disabled={loading} variant="primary" active={role === userRole} onClick={() => setUserRole(role)}>{role}</Button>)}
                            </ButtonGroup>
                        </Col>



                    </Row>

                    {loading && <ProgressLoading />}

                </div>
            </div>

        </>
    );
}

export default UserRole
