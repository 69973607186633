import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Overlay, Popover, Row, Spinner, Tooltip } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiRequest } from '../../../../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DisableData = ({ user, refresh }: any) => {

  return (
    <div>


      <DisableDataView user={user} />


      <hr />
    </div>
  )
}
const DisableDataView = ({ user }: any) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };




  useEffect(() => {
    if (data !== undefined) {

      window.location.reload();
    }
  }, [data])


  const handleDisable = () => {
    debugger
    setLoading(true)
    apiRequest({
      endpoint: '/users/' + user?.id,
      method: 'put',
      body: { isActive: !user?.isActive }
    }).then((data) => {
      setData(data)
    }).finally(() =>

      setTimeout(() => setLoading(false), 500))
  }
  return (<>


    <div className="card card-frame">
      <div className="card-body ">
        A desativação de uma conta pode afetar o acesso de serviços e dados. Tenha certeza de que deseja continuar com a alteração.

        <hr />

        <div ref={ref}>

          <Button variant={user?.isActive ? 'warning' : 'success'} disabled={loading} onClick={handleClick}>
            {loading && <Spinner animation="border" role="status" size={'sm'}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>}  {' '}
            {user?.isActive ? 'Desactivar' : 'Activar'} conta

          </Button>
          <PopOverCard status={user?.isActive} show={show} handleDisable={handleDisable} target={target} setShow={setShow} ref={ref} />

        </div>
      </div>
    </div>

  </>)
}
const PopOverCard = ({ show, target, setShow, ref, handleDisable, status }: any) => {

  return (<>
    <Overlay
      show={show}
      target={target}
      placement="bottom"
      container={ref}
      containerPadding={20}
    >
      <Popover id="popover-contained">
        <Popover.Body>
          <strong>Atencao!</strong> <br />
          {status
            ? 'A conta será desativada e o utilizador não poderá mais aceder ao sistema.'
            : 'A conta será ativada e o utilizador poderá aceder ao sistema normalmente.'
          }




          <br />
          <button type="button" onClick={() => setShow(false)} className="btn border btn-sm btn-outline-white">X</button>
          <button onClick={handleDisable} type="button" className="btn border btn-sm btn-outline-primary">
            Confirmar
          </button>
        </Popover.Body>
      </Popover>
    </Overlay>
  </>)
}

export default DisableData
