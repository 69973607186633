import PageLink from "./PageLink";


export type Props = {
    currentPage: number;
    lastPage: number;
    maxLength: number;
    setCurrentPage: (page: number) => void;
  };
  
  export default function Pagination({
    currentPage,
    lastPage,
    maxLength,
    setCurrentPage,
  }: Props) {
    const pageNums = [1, 2, 3];
  
    return (
      <nav className="pagination" aria-label="Pagination">
        <PageLink
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </PageLink>
        {pageNums.map((pageNum, idx) => (
          <PageLink
            key={idx}
            active={currentPage === pageNum}
            disabled={isNaN(pageNum)}
            onClick={() => setCurrentPage(pageNum)}
          >
            {pageNum}
          </PageLink>
        ))}
        <PageLink
          disabled={currentPage === lastPage}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </PageLink>
      </nav>
    );
  }