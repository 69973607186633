import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { apiRequest } from '../../../../../../utils/api'
import MainData from './MainData'
import ContactData from './ContactData'
import BioData from './BioData'

const PersonalData = ({user}:any) => {

  const [data, setData] = useState<any>({});
  const [refresh, setrefresh] = useState<number>();
  useEffect(() => {
    if(user?.id){
    apiRequest({
        endpoint: '/users-data/' + user.id,
        method: 'get',
    }).then(({data}: any) => {
        setData({id:user?.id, email:user?.email,...data})
    }).catch(err => console.error(err))
  }
  }, [user, refresh])
  

  
  return (<>
  
    <MainData userData={data} refresh={()=>setrefresh(Math.random())} />
    
    <ContactData userData={data} refresh={()=>setrefresh(Math.random())}/>    

    <BioData  userData={data} refresh={()=>setrefresh(Math.random())}/>
    
    </>
  )
}


export default PersonalData
