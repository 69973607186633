import React, { useEffect, useState } from 'react'
import { Col, ListGroup, Modal, Row } from 'react-bootstrap';

import { apiRequest } from '../../../../../../../utils/api';
import { ProgressLoading, PulseLoading } from '../../../../../../Common/components/Loading';
import { useDebounce } from '@uidotdev/usehooks';

const MentorCreate = ({ show, setShow, refresh, program }: any) => {

    const handleClose = () => setShow(false);

    return (

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar  Mentor ao Programa</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MentorCreateForm program={program} closeModal={handleClose} refresh={refresh} />
            </Modal.Body>
        </Modal>

    )
}

const MentorCreateForm = ({ refresh, closeModal, program }: any) => {

    const [error, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const [userFilter, setUserFilter] = useState<string>("");
    const [selectedUser, setUser] = useState<any>({});
    const [{ data: users }, setUsers] = useState<any>({ data: [] });

    const debouncedSearchTerm = useDebounce(userFilter, 500);

    useEffect(() => {
        if (userFilter?.length > 2) {
            if (debouncedSearchTerm) {
                setLoading(true)
                apiRequest({
                    endpoint: '/users',
                    method: 'get',
                    urlQueryParams: { email: userFilter, role: 'MENTOR' }
                }).then((data) => {
                    setUsers(data)
                }).catch(({ data }) => setErrors(data))
                    .finally(() => setLoading(false))
            }

        } else {
            setUsers([]);
        }
    }, [debouncedSearchTerm])

    useEffect(() => {

        if (savedResponse !== undefined) {

            refresh();
            closeModal()
        }
    }, [savedResponse])

    const formSubmit = () => {
        setLoading(true)
        apiRequest({
            endpoint: '/Program-mentors',
            method: 'post',
            body: { programId: program?.id, userId: selectedUser?.id }
        }).then((data) => {
            setSavedResponse(data)

            refresh();
            closeModal()
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false))
    }

    return (<>
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: 15 }}>

            {error?.error && <div className='alert alert-danger'>{error?.error?.message}</div>}
            <Row>
                <Col xs={12}>
                    <label htmlFor="title">Mentor</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Escreva o email de um mentor para adicionar ao programa"
                        type="text"
                        onChange={(e: any) => setUserFilter(e.target.value)}
                    >
                    </input>
                </Col>
                {loading && <ProgressLoading />}

                <Col xs={12}>
                    <ListGroup defaultActiveKey="#link1">
                        {users?.map((user: any, i: number) =>
                            <ListGroup.Item action onClick={() => setUser(user)} active={user?.id === selectedUser?.id} >
                                <div className="d-flex px-2">
                                    <div className="avatar avatar-sm rounded-circle bg-gray-100 me-2 my-2">
                                        <img src="/assets/img/unknow.png" className="w-160" alt="spotify" />
                                    </div>
                                    <div className="my-auto">
                                        <h6 className="mb-0 text-sm"><b>{user?.email}</b></h6>
                                        <small className="mb-0 text-sm">{user?.role}</small>
                                    </div>
                                    <i className="fa fa-heart"></i>
                                </div>
                            </ListGroup.Item>
                        )}
                        {users?.length === 0 && <ListGroup.Item disabled >
                            <div className="d-flex px-2 text-center">


                                <small className="mb-0 text-sm text-center"> :: Nenhum mentor encontrado</small>

                            </div>
                        </ListGroup.Item>}
                    </ListGroup>
                </Col>

                <Col>

                    <hr />
                    <button type="button" onClick={formSubmit} className="btn btn-dark btn-icon d-flex align-items-center me-2" >

                        {loading ? <PulseLoading /> :
                            <span className="btn-inner--icon">

                            </span>}
                        <span className="btn-inner--text">Salvar</span>

                    </button>
                </Col>
            </Row>
            {loading && <ProgressLoading />}
        </div>


    </>
    );
}

export default MentorCreate
