import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../features/auth/authSlice';
import { LoginCredentials } from '../../../features/auth/types';
import { AppDispatch, RootState } from '../../../app/store';

import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Loading, ProgressLoading, PulseLoading } from '../../Common/components/Loading';
import { useNavigate } from 'react-router-dom';
interface LoginFormInput {
  email: string,
  password: string
}
const LoginForm: React.FC = () => {


  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false)
  const { register, handleSubmit, reset, formState: { errors } } = useForm<LoginFormInput>();

  const onSubmit: SubmitHandler<LoginFormInput> = (data: any) =>
    formSubmit(data);

  const navigate = useNavigate();

  const formSubmit = (data: LoginFormInput) => {
    setLoading(true)
    dispatch(loginUser(data));
  };
  const auth = useSelector((state: RootState) => state?.auth);

  useEffect(() => {
    setLoading(false)
    if (auth.user?.email) {
      navigate('/my/dashboard');
    }
  }, [auth])


  return (


    <>
      {auth?.error?.error?.code === 'ERR_NETWORK' &&
        <div className="alert alert-danger" role="alert">
          {auth?.error?.error?.message}
        </div>
      }
      {auth?.error?.error?.code === 'USER_INVÁLIDO' &&
        <div className="alert alert-danger" role="alert">
          {auth?.error?.error?.message}
        </div>
      }
      {auth?.error?.error?.code === 'ERR_BAD_REQUEST' &&
        <div className="alert alert-info" role="alert">
          Credenciais inválidas, certifique que colocou o Email/ palavra-passe correctamente
        </div>
      }
      {auth?.error?.message &&
        <div className="alert alert-danger" role="alert">
          {auth?.error?.message}
        </div>
      }
      <form role="form" onSubmit={handleSubmit(onSubmit)} >

        <div>
          <label htmlFor="title">Email</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="email"
            placeholder="E-mail"
            type="email"

            {...register("email", { required: "Email is required" })}
          >
          </input>
          <span className='error'>{errors.email && <p>{errors.email.message}</p>}</span>
        </div>

        <div>
          <label htmlFor="title">Palavra-passe</label>
          <input
            className="form-control ps-0"
            maxLength={256}
            data-name="email"
            placeholder="Palavra-passe"
            type="password"

            {...register("password", { required: "Email is required" })}
          >
          </input>
          <span className='error'>{errors.password && <p>{errors.password.message}</p>}</span>
        </div>


        <div className="d-flex align-items-center">
          <div className="form-check form-check-info text-left mb-0">
            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
            <label className="font-weight-normal text-dark mb-0" htmlFor="flexCheckDefault">
              Lembre-se por 14 dias
            </label>
          </div>
          <a href="javascript:;" className="text-xs font-weight-bold ms-auto">Esqueceu sua senha?</a>
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-dark w-100 mt-4 mb-3" disabled={loading}>{loading && <PulseLoading />}{" "}Entrar</button>
          <button type="button" className="btn btn-white btn-icon w-100 mb-3">
            <span className="btn-inner--icon me-1">
              <img className="w-5" src="../assets/img/logos/google-logo.svg" alt="google-logo" />
            </span>
            <span className="btn-inner--text">Entrar com o Google</span>
          </button>
        </div>
        {loading && <ProgressLoading />}
      </form>
    </>
  );
};

export default LoginForm;