import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { UserList } from './Users'
import { UserDetails } from './Users/UserDetails'
import { TalentPool } from './TalentPool/TalentPool'
import { Programs } from './TalentPool/Programs/Programs'
import { ProgramDetails } from './TalentPool/Programs/ProgramDetails'

export const ManagerBackOfficeRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<>Dashboard</>} />
      <Route path="/users" element={<UserList />} />
      <Route path="/users/:id" element={<UserDetails />} />
      <Route path="/talent-pool" element={<TalentPool />} />
      <Route path="/talent-pool/programs" element={<Programs />} />
      <Route path="/talent-pool/programs/:id" element={<ProgramDetails />} />
      <Route path="/talent-pool/:id" element={<UserDetails />} />




      
      <Route path="/*" element={<>Properties</>} />
    </Routes>
  )
}
